import { PropsWithChildren } from 'react';

export function OrgCard({
  organizationName,
  children,
}: PropsWithChildren<{ organizationName: string }>) {
  return (
    <div className="flex max-w-2xl flex-col gap-4 bg-background">
      <div className="flex flex-col gap-1">
        <h1 className="text-4xl font-bold">{organizationName}</h1>
        <h2 className="text-lg text-muted-foreground">
          Upcoming events for {organizationName}
        </h2>
      </div>
      <div className="flex flex-col gap-8">{children}</div>
    </div>
  );
}
