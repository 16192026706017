import { format } from 'date-fns';
import classNames from 'classnames';

import { Event } from '~/types';
import { Markdown } from '~/components/CustomMarkdown';
import { Button } from '~/components/ui/button';
import { Link } from '@remix-run/react';
import { useState } from 'react';
import { ArrowRightIcon } from 'lucide-react';

interface Props {
  orgSlug: string;
  eventSlug: string;
  event: Event;
}

export function OrgEvent({ orgSlug, eventSlug, event }: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  let dateString = format(new Date(event.startDate), 'MMMM d, yyyy');

  if (event.startDate !== event.endDate) {
    dateString += ` - ${format(new Date(event.endDate), 'MMMM d, yyyy')}`;
  }

  return (
    <div className="flex flex-col gap-2">
      <div>
        <h3 className="text-3xl font-bold">{event.name}</h3>
        <p className="text-lg">{dateString}</p>
      </div>
      <div className="rounded-lg border p-4 pt-4 text-sm">
        <div className="relative">
          <div
            role="button"
            tabIndex={0}
            className={classNames(
              'transition-all duration-300 ease-in-out',
              isExpanded
                ? 'max-h-[400px] overflow-y-auto'
                : 'max-h-[120px] overflow-y-hidden',
            )}
            onClick={() => setIsExpanded(true)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setIsExpanded(true);
              }
            }}
          >
            <Markdown>{event.description}</Markdown>
          </div>
          <div
            className={classNames(
              'pointer-events-none absolute inset-x-0 bottom-0 h-full bg-gradient-to-b from-transparent via-transparent via-70% to-background transition-opacity duration-300 ease-in-out',
              isExpanded ? 'opacity-0' : 'opacity-100',
            )}
          />
        </div>
        <div className="flex justify-end">
          <Button
            variant="link"
            size="sm"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Read less' : 'Read more'}
          </Button>
        </div>
        <div className="flex w-full">
          <Link to={`/${orgSlug}/${eventSlug}`} className="w-full">
            <Button className="w-full">
              {event.name} Registration <ArrowRightIcon className="h-4 w-4" />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
