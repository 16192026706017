import { LoaderFunction, MetaFunction } from '@remix-run/cloudflare';
import { useLoaderData } from '@remix-run/react';
import { organizationGet } from '~/model/organizationGet';
import { OrganizationPage } from '~/pages/OrganizationPage';
import { createOrg404Response } from '../utils/errorResponses';

interface LoaderData {
  organization: NonNullable<Awaited<ReturnType<typeof organizationGet>>>;
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const loaderData = data as LoaderData;

  return [
    { title: loaderData.organization.name },
    {
      name: 'description',
      content: loaderData.organization.description,
    },
  ];
};

export const loader: LoaderFunction = async ({
  request,
  params,
  context,
}): Promise<LoaderData> => {
  return context.performance.loaderSpan('$orgSlug._index', async () => {
    const { orgSlug } = params;

    if (!orgSlug) {
      throw new Error('orgSlug is required');
    }

    await context.authenticator.isAuthenticated(request, {
      failureRedirect: `/login/${orgSlug}`,
    });

    const organization = await organizationGet({ orgSlug, context });

    if (!organization) {
      throw createOrg404Response({ orgSlug });
    }

    return {
      organization,
    };
  });
};

export default function OrgPage() {
  const { organization } = useLoaderData<LoaderData>();

  return <OrganizationPage organization={organization} />;
}
