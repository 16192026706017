import { eventTable, organizationsTable } from '~/db/schema';
import { OrgCard } from './OrganizationPage/OrgCard';
import { OrgEvent } from './OrganizationPage/OrgEvent';
import { Page } from './Page';

interface OrganizationPageProps {
  organization: typeof organizationsTable.$inferSelect & {
    events?: Array<typeof eventTable.$inferSelect>;
  };
}

export function OrganizationPage({ organization }: OrganizationPageProps) {
  if (!organization.events || organization.events.length === 0) {
    return (
      <div className="relative min-h-screen w-full p-4">
        <div className="flex min-h-screen w-full items-center justify-center">
          <OrgCard organizationName={organization.name}>
            <div>No upcoming events</div>
          </OrgCard>
        </div>
      </div>
    );
  }

  // duplicate events for testing
  // for (let i = 0; i < 10; i++) {
  //   const newEvent = { ...organization.events[0] };
  //   newEvent.id = newEvent.id + i;
  //   newEvent.name = `${newEvent.name}-${i}`;
  //   newEvent.description = `${newEvent.description}-${i}`;
  //   newEvent.slug = `${newEvent.slug}-${i}`;

  //   organization.events.push(newEvent);
  // }

  return (
    <Page>
      <div className="flex w-full flex-col justify-between">
        <div className="flex w-full justify-center px-4 md:pt-4">
          <OrgCard organizationName={organization.name}>
            {organization.events.map((event) => {
              return (
                <OrgEvent
                  key={event.id}
                  orgSlug={organization.slug}
                  eventSlug={event.slug}
                  event={event}
                />
              );
            })}
          </OrgCard>
        </div>
      </div>
    </Page>
  );
}
